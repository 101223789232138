@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
  position: relative;
}

/*
 * Sidebar
 */

$sidebar-background-color: white;
$sidebar-active-color: #333333;

/* Hide for mobile, show later */
.sidebar {
  display: none;
}
@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    background-color: $sidebar-background-color;
    border-right: 1px solid #eee;
    font-size: 1.3em;
    color: black;
    font-weight: 300;
  }
}

/* Sidebar navigation */
.nav-sidebar {
  margin-right: -21px; /* 20px padding + 1px border */
  margin-bottom: 20px;
  margin-left: -20px;
}
.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px;
  color: black;
}
.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
  color: white;
  background-color: $sidebar-active-color;
}


/*
 * Main content
 */

.main {
  padding: 20px;
}
@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.main .page-header {
  margin-top: 0;
}

.table tbody>tr>td{
  vertical-align: middle;
}

/*
 * Navbar Customization
 */
$bgDefault      : #333333;
$bgHighlight    : #333333;
$colDefault     : #ffffff;
$primaryColor   : #1d9761;
$navHighlight   : #26C281;
$footerBg       : #ffffff;
$footerHighlight: #333333;
$dropDown       : true;

.navbar-default {
  background-color: $bgDefault;
  border-color: $bgHighlight;
  .navbar-brand {
    color: $colDefault;
    &:hover, &:focus {
      color: $navHighlight; }}
  .navbar-text {
    color: $navHighlight; }
  .navbar-nav {
    > li {
      > a {
        color: $colDefault;
        &:hover,  &:focus {
          color: $navHighlight; }}
      @if $dropDown {
        > .dropdown-menu {
          font-size: 1.1em;
          background-color: $bgDefault;
          > li {
            > a {
              color: $colDefault;
              &:hover,  &:focus {
                color: $navHighlight;
                background-color: $bgHighlight; }}
            > .divider {
              background-color: $bgHighlight;}}}}}
    @if $dropDown {
      .open .dropdown-menu > .active {
        > a, > a:hover, > a:focus {
          color: $navHighlight;
          background-color: $bgHighlight; }}}
    > .active {
      > a, > a:hover, > a:focus {
        color: $navHighlight;
        background-color: $bgHighlight; }}
    > .open {
      > a, > a:hover, > a:focus {
        color: $navHighlight;
        background-color: $bgHighlight; }}}
  .navbar-toggle {
    border-color: $bgHighlight;
    &:hover, &:focus {
      background-color: $bgHighlight; }
    .icon-bar {
      background-color: $colDefault; }}
  .navbar-collapse,
  .navbar-form {
    border-color: $colDefault; }
  .navbar-link {
    color: $colDefault;
    &:hover {
      color: $navHighlight; }}}
@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu {
    > li > a {
      color: $colDefault;
      &:hover, &:focus {
        color: $navHighlight; }}
    > .active {
      > a, > a:hover, > a:focus {
        color: $navHighlight;
        background-color: $bgHighlight; }}}
}

.footer {
  //position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: $footerBg;
  margin-top: 20px;

  .content {
    border-top: 1px solid #ccc;
    padding-bottom: 50px;
    text-align:center;
  }

  img:hover {
    opacity: 0.3;
  }

  a {
    color: #aaa;
    font-size: 1.2em;
    font-weight: 300;
  }

  a:hover,
  a:focus{
    color: $footerHighlight;
    background-color: transparent;
  }

  h2 {
    color: #aaa;
    font-size: 1.1em;
    font-weight:300;
  }

  h3 {
    color: #aaa;
    font-size: 1em;
    font-style: italic;
    font-weight:300;
    margin-bottom: 10px;
  }
}

.navbar-absolute {
  position:absolute;
  width:100%;
}

.container-navbar-offset {

}

#home-info-top {
  background-color: $primaryColor;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 50px;
  margin-top: 50px;
  overflow: hidden;
  color: white;

  background-color: rgba(255,255,255,0.85);
  color: black;

  h1 {
    font-size: 2em;
    text-align: center;
    font-weight: 300;
  }

  h2 {
    font-size: 1.6em;
    font-weight: 300;
    text-align: center;
  }

  ol {
    font-size: 1.4em;
    font-weight: 300;
    list-style-position: inside;
  }

  a {
    font-size: 1.2em;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .btn-default {
    &.btn-transparent {
      @include button-variant($btn-default-bg, transparent, $btn-default-bg);
      &:hover {
        color: #1d9761;
        background-color: white;
        border-color: white;
      }
    }
  }

  .internalContainer {
    max-width: 450px;
    margin: 0 auto;
  }

  @media (min-width: $screen-lg-min) {
    max-width: 500px;
    //float: right !important;
  }
}

#home-info-bottom {
  background-color: white;
  padding-bottom: 50px;

  h2 {
    color: $primaryColor;
    font-size: 1.5em;
    padding-left:15px;
  }

  a {
    color: gray;
  }

  img {
    margin: 0 auto;
  }

  //center bullet image and text
  .infoList {
    @media (min-width: $screen-xs-min) {
      height: 65px;

      img, p {
        position: relative;
        top: 50%;
        transform: perspective(1px) translateY(-50%);
        max-height: 40px;
      }
    }

    padding-left: 0;
  }
}

#home-top-row {
  padding-top:70px;
  background-size: cover;
  background-position: center center;

  @media (min-width: $screen-md) {
    background-image: url('/img/homeBg-medium.jpg');
  }

  @media (max-width: $screen-md-min) {
    background-image: url('/img/homeBg-small.jpg');
  }

}

#home-info-row {
  background-color: white;
}

.homeInfoContainer {
  max-width: 1280px;
  margin: 0 auto;
}

.page {

  padding-top: 70px;

  h1 {
    text-align: center;
    text-transform: uppercase;
  }

  h2 {
    text-align: center;
    font-size: 1.1em;
    color: grey;
    font-weight: 300;
  }

  h3 {
    text-align: center;
    font-size: 1.5em;
    color: $primaryColor;
  }

  img {
    margin-bottom: 15px;
  }
}

.section-title {
  padding-bottom: 30px;

  h2 {
    a {
      color: darkgray;
      text-decoration: underline;
    }
  }
}

p {
  font-size: 1.1em;
}

.team {
  margin-bottom: 50px;
  min-height: 300px;

  img {
    margin: 0 auto;
    margin-bottom: 20px;
    max-width: 200px;
  }

  p {
    text-align: center;
    margin: 0;
    font-weight: 300;
  }

  .name {
    font-weight: 600;
    color: $primaryColor;
  }
}

.services {
  .service {
    text-align: center;
  }

  img {
    margin: 0 auto;
    max-width: 200px;
  }

  p {
    text-align: center;
  }

  a {
    color: gray;
  }

  .form-group {
    a {
      text-decoration: underline;
    }
  }
}

.service-form {
  display: none;
}

#item-fleet {
  min-height: 330px;

  p {
    text-align: center;
    font-size: 1.1em;
    font-weight: 300;
    margin-bottom: 5px;
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 0.2em;
    font-weight: 400;
    color: gray;
    font-size: 1.3em;
  }
}

.itemImg {

  a {
    height: 150px;
    position: relative;
    float: left;
    width: 100%;
    text-decoration: none;

    h4 {
      color: black;
    }
  }

  img { //center image in div
    position: relative;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
    margin: 0 auto; //center horizontally
    max-height: 100%;
  }

  .overlay {
    text-align: center;
    top: -50%;
    position: relative;
    display:none;
  }
}

.pagination {
  li {
    a {
      color: gray;
    }

    a:hover {
      color: gray;
    }
  }

  .active {
    a,a:hover {
      background-color: gray;
      border-color: #DDDDDD;
    }
  }

  .disabled {
    a {
      display: none;
    }
  }
}

.filter {

  margin-bottom: 30px;

  h2 {
    font-size: 1.5em;
    color: grey;
    text-transform: uppercase;
    font-weight: 300;
    font-style: normal;
  }

  h3 {
    font-size: 1.2em;
    color: gray;
    font-weight: 300;
  }

  .list-inline {
    li {
      font-size: 1.4em;
      font-weight: 300;

      a {
        color: gray;
      }

      a:hover {
        color: $primaryColor;
        text-decoration: none;
      }
    }

    .active {
      color: $primaryColor;
      font-weight: 400;
    }
  }

  button {
    margin-top: 20px;
  }

  .form-control {
    max-width: 180px;
    margin: 0 auto;
  }
}

.filterTop {
  margin-bottom: 20px;
}

.contact {
  p {
    font-weight: 300;
  }

  h3 {
    font-size: 1.7em;
    color: gray;
    font-weight: 300;
  }

  a {
    color: gray;
    text-decoration: underline;
  }
}

.faq, .careers {

  margin-bottom: 30px;

  a {
    color: gray;
    text-decoration: underline;
  }

  h3 {
    text-align: left;
    color: gray;
  }

  ul {
    list-style: none;
    padding-left: 0;
    font-size: 1.3em;
    font-weight: 300;
    line-height: 1.6em;
    color: gray;

    .active a {
      color: $primaryColor;
      font-weight: 400;
    }

    a {
      color: gray;
      text-decoration: none;
    }

    a:hover {
      color: $primaryColor;
    }

    h3 {
      font-size: 1.4em;
      font-weight: 300;
      color: black;
    }
  }

  h4 {
    font-size: 1.4em;
  }

  p {
    font-weight: 300;
    font-size: 1.3em;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.faq {
  h4 {
    font-size: 1.6em;
    font-weight: 300;
    font-style: italic;
  }

  .categoryHeading {
    h4 {
      font-weight: 400;
      position: relative;
      top: 15px;
      font-style: normal;
    }
  }

  .qnaSection {
    list-style-type: square;
  }
}

.careers {

  h3 {
    color: black;
  }

  h4 {
    color: gray;
  }

  .generalInfo {
    margin-top: 30px;

    h3 {
      font-weight: 300;
      text-align: center;
      color: black;
    }
  }

  ul {
    list-style: circle;
  }

}

/* Show and affix the side nav when space allows it */
@media (min-width: $screen-xs-max) {
  .bs-docs-sidebar .nav > .active > ul {
    display: block;
  }
  /* Widen the fixed sidebar */
  .bs-docs-sidebar.affix,
  .bs-docs-sidebar.affix-bottom {
    //width: 213px;
  }
  .bs-docs-sidebar.affix {
    position: fixed; /* Undo the static from mobile first approach */
    top: 70px;
  }
  .bs-docs-sidebar.affix-bottom {
    position: absolute; /* Undo the static from mobile first approach */
  }
  .bs-docs-sidebar.affix-bottom .bs-docs-sidenav,
  .bs-docs-sidebar.affix .bs-docs-sidenav {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (max-width: $screen-xs-max) {
  .bs-docs-sidebar.affix {
    position: relative;
  }
}

@media (min-width: $screen-lg-min) {
  /* Widen the fixed sidebar again */
  .bs-docs-sidebar.affix-bottom,
  .bs-docs-sidebar.affix {
    //width: 263px;
  }
}

//SCROLLSPY
#ksa-scrollspy {

  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  .nav {
    padding: 0 20px 0 0;
    max-width: 250px;
  }

  li {
    margin-bottom: 1.0em;
    line-height: 1em;
  }

  li.active {
    color: gray;
  }

  a {
    padding: 0;
  }

  a:hover {
    background-color: transparent;
  }

  a:focus {
    background-color: transparent;
  }
}

table .form-group {
  margin: 0;
  margin-right: 20px
}

// Alternate buttons styles inverse and transparent
// --------------------------------------------------
.btn-default {
  &.btn-inverse {
    @include button-variant($btn-default-bg, $btn-default-color, $btn-default-bg);
    &:hover {
      @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
    }
  }
  &.btn-transparent {
    @include button-variant($btn-default-bg, transparent, $btn-default-bg);
    &:hover {
      @include button-variant($btn-default-bg, darken($btn-default-bg, 25%), $btn-default-bg);
    }
  }
}

.btn-primary {
  &.btn-inverse {
    @include button-variant($btn-primary-bg, $btn-primary-color, $btn-primary-bg);
    &:hover {
      @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
    }
  }
  &.btn-transparent {
    @include button-variant($btn-primary-bg, transparent, $btn-primary-bg);
    &:hover {
      @include button-variant($btn-primary-bg, darken($btn-primary-bg, 25%), $btn-primary-bg);
    }
  }
}

.btn-success {

  background-color: #1db25d;
  border-color: #18a052;

  &:hover {
    background-color: #09955E;
  }

  &.btn-inverse {
    @include button-variant($btn-success-bg, $btn-success-color, $btn-success-bg);
    &:hover {
      @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
    }
  }
  &.btn-transparent {
    @include button-variant($btn-success-bg, transparent, $btn-success-bg);
    &:hover {
      @include button-variant($btn-success-bg, darken($btn-success-bg, 25%), $btn-success-bg);
    }
  }

  &.disabled {
    opacity: 0.25;
  }
}

.btn-info {
  &.btn-inverse {
    @include button-variant($btn-info-bg, $btn-info-color, $btn-info-bg);
    &:hover {
      @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
    }
  }
  &.btn-transparent {
    @include button-variant($btn-info-bg, transparent, $btn-info-bg);
    &:hover {
      @include button-variant($btn-info-bg, darken($btn-info-bg, 25%), $btn-info-bg);
    }
  }
}

.btn-warning {
  &.btn-inverse {
    @include button-variant($btn-warning-bg, $btn-warning-color, $btn-warning-bg);
    &:hover {
      @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
    }
  }
  &.btn-transparent {
    @include button-variant($btn-warning-bg, transparent, $btn-warning-bg);
    &:hover {
      @include button-variant($btn-warning-bg, darken($btn-warning-bg, 25%), $btn-warning-bg);
    }
  }
}

.btn-danger {
  &.btn-inverse {
    @include button-variant($btn-danger-bg, $btn-danger-color, $btn-danger-bg);
    &:hover {
      @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
    }
  }
  &.btn-transparent {
    @include button-variant($btn-danger-bg, transparent, $btn-danger-bg);
    &:hover {
      @include button-variant($btn-danger-bg, darken($btn-danger-bg, 25%), $btn-danger-bg);
    }
  }
}

#navbarHome.affix-top {
  background-color: transparent;
  border-color: transparent;

  .dropdown.open a {
    background-color: transparent;

    color: $primaryColor;
    &:hover,  &:focus {
      color: $navHighlight;
    }
  }

  .dropdown-menu {
    font-size: 1.1em;
    background-color: rgba(255,255,255,0.7);
  }

  .navbar-nav {
    > li {
      > a {
        color: $primaryColor;
        &:hover,  &:focus {
          color: $navHighlight; }}
      }
    }

  @media (max-width: $screen-xs-max) {
    .collapse, .collapsing {
      background-color: $bgDefault;
    }

    .dropdown.open a, .dropdown-menu {
      background-color: $bgDefault;
      color: white;
    }

    .navbar-nav > li > a {
      color:white;
    }

    img {
      max-height: 100px;
    }
  }
}

.modal-promotion {
  img {
    margin: 0 auto;
    display: initial;
  }
}

.btn-round {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.btn-round.btn-lg {
  width: 48px;
  height: 48px;
}

.btn-round.btn-sm {
  width: 34px;
  height: 34px;
}

.btn-round.btn-xs {
  width: 24px;
  height: 24px;
}

.colorButton {
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.colorActive {
  text-shadow: -2px 0 #f5f5f5, 0 2px #f5f5f5, 2px 0 #f5f5f5, 0 -2px #f5f5f5;
  padding-top: 4px;
}

#ksa-sidebar {
  max-width: 300px;

  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  @media (max-width: $screen-xs-max) {
    margin: 0 auto;
  }
}

.colorSquareContainer {
  text-align: center;
  padding: 0 40px 0 40px;
}

.colorSquare {
  width:20px;
  height:20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  display:inline-block
}

.page-whyChooseUs {

  @media (max-width: $screen-md-min) {
    text-align: center;
  }

  h2 {
    text-align: left;
    font-size: 1.5em;
    color: grey;
    font-style: normal;

    @media (max-width: $screen-md-min) {
      text-align: center;
    }
  }

  .imgSection {
    text-align: center;
  }

  .infoSection {
    margin-bottom: 30px;

    a {
      color: green;
      text-decoration: underline;
    }

    @media (max-width: $screen-md-min) {
      margin-bottom: 80px;
    }
  }
}

/* Modify chrome autofill color for inputs */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

#compareBar {
  width:100%;
  background: white;
  border-bottom: 1px solid #ccc;
  height: 60px;
  position: fixed;
  top: 0px;
  z-index: 2000; //must be above bootstrap's navbar z-index of '1030'
  display: none;

  .container {
    position: relative;
    top: 50%;
    transform: perspective(1px) translateY(-50%);

    .btn-vehicle {
      display: none;
    }
  }
}

/* centered columns styles */
.row-centered {
  text-align:center;
}
.col-centered {
  display:inline-block;
  float:none;
  /* reset the text-align */
  text-align:left;
  /* inline-block space fix */
  margin-right:-4px;
}

.consentCheckBox {
  width: 5%;
  float:left;
  text-align: center;
  margin-right: 3px;
}

.consentText {
  width: 85%; float:left
}

@media (max-width: $screen-xs-max) {
  .btn-responsive {
    padding:2px 4px;
    font-size:80%;
    line-height: 1;
    border-radius:3px;

    .glyphicon {
      display: inline;
    }
  }
}

.back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display:none;
}

select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20fill%3D%22%23555555%22%20%0A%09%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%22-261%20145.2%2024%2024%22%20style%3D%22enable-background%3Anew%20-261%20145.2%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-245.3%2C156.1l-3.6-6.5l-3.7%2C6.5%20M-252.7%2C159l3.7%2C6.5l3.6-6.5%22%2F%3E%0A%3C%2Fsvg%3E") !important;
  padding-right: 25px;
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

a.btn.btn-success.btn-xs.btn-item-compare.btn-inverse:hover {
  background-color: white;
  color: black;
}

/* centered columns styles */
.row-centered {
  text-align:center;
}

.col-centered {
  display:inline-block;
  float:none;
  /* reset the text-align */
  text-align:left;
  /* inline-block space fix */
  margin-right:-4px;
}

.filterColorButton {

  border-width: 1px;
  opacity: 0.4;
  margin-bottom: 0.3em;


  &.active {
    opacity: 1.0;
    background-color: white;
    box-shadow: none;
  }
}

.form-group-filter {

  opacity: 0.4;

  &.active {
    opacity: 1.0;
  }
}

.faqQnA {

  .faqQuestion {
    cursor: pointer;
  }

  .faqAnswer {
    display: none;
  }
}

//for indentation of bullets after first line
ol {
  counter-reset: foo;
  display: table;
}

ol > li {
  counter-increment: foo;
  display: table-row;
}

ol > li::before {
  content: counter(foo) ".";
  display: table-cell; /* aha! */
  text-align: right;
  padding-right: 5px;
}

.careers {
  .careerPositions {
    h3 {
      cursor: pointer;
    }

    .careerDetails {
      display: none;
    }
  }
}

.carousel-control {
  background-image: none !important;
  color: white !important;
}

.carousel {

  .featureImgContainerContainer {
    width: 100%;
    display: inline-block;
    position: relative;
  }

  .featureImgContainerContainer:after {
    //padding-top: 56.25%; /* 16:9 ratio */
    padding-top: 66.6%;
    display: block;
    content: '';
  }

  .featureImgContainer {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.newsTab {

  margin-bottom: 50px;

  img {
    margin: 0;
  }

  .featureImgContainer {
    height: 250px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.newsTabInfo {

  margin-top: -20px;

  h3 {
    color: black;
    font-weight: 400;
    text-align: left;
    margin-bototm: 15px;
  }

  h4 {
    color: #bbb;
    font-size: 1em;
    text-align: left;
    font-style: italic;
    font-weight: 300;
  }

  img {
    height: 45px;
    border-radius: 100%;
    margin: 0px;
    margin-left: 10px;
  }

  p {
    margin: 30px 0 30px 0;
    line-height: 2.0em;
  }

  .divider {
    margin: 0 10px 0 10px;
  }
}

.tagButton {

  border-width: 1px;
  opacity: 0.4;
  margin-bottom: 0.3em;


  &.active {
    opacity: 1.0;
    background-color: white;
    box-shadow: none;
  }
}

.page-newsletter {

  h1 {
    font-size: 2.2em;
  }

  h2 {
    font-size: 1.8em;
    color: grey;
    font-weight: 300;
  }

  h5 {
    font-size: 1.1em;
    color: grey;
    font-weight: 300;
  }

  h6 {
    font-size: 0.8em;
    color: grey;
    font-weight: 300;
  }

  .newsHeader {
    margin-bottom: 30px;
    padding: 0;
  }

  .newsHeaderInfo {
    margin-top: -20px;
    text-align: center;

    h3 {
      color: black;
      font-weight: 400;
      margin-bototm: 15px;
      font-size: 2em;
    }

    h4 {
      color: #bbb;
      font-size: 1.3em;
      font-style: italic;
      font-weight: 300;
    }

    img {
      height: 45px;
      border-radius: 100%;
      margin: 0px;
    }

    .divider {
      margin: 0 10px 0 10px;
    }
  }

  .featureImgContainer {
    height: 70vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .divider {
    margin: 0 10px 0 10px;
  }

  .mainContent {
    margin-bottom: 60px;
    line-height: 2em;
  }

  p {
    font-size: 1.3em;
  }
}

$errorPlaceholderColor : #EC644B;
$successPlaceholderColor: $primaryColor;

.newsletter {
  .newsletter-sidebar {
    margin-bottom: 30px;
  }

  .form-control:focus {
    border-color: $primaryColor;
    //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(38, 194, 129, 0.6);
    box-shadow: none;
  }

  input {
    &.error {
      &::-webkit-input-placeholder { /* Chrome */
        color: $errorPlaceholderColor;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $errorPlaceholderColor;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $errorPlaceholderColor;
        opacity: 1;
      }
      &:-moz-placeholder { /* Firefox 4 - 18 */
        color: $errorPlaceholderColor;
        opacity: 1;
      }
    }

    &.success {
      &::-webkit-input-placeholder { /* Chrome */
        color: $successPlaceholderColor;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $successPlaceholderColor;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $successPlaceholderColor;
        opacity: 1;
      }
      &:-moz-placeholder { /* Firefox 4 - 18 */
        color: $successPlaceholderColor;
        opacity: 1;
      }
    }
  }
}

#newsletter-item-form {
  #facebook-og-tags-container {
    margin-top: 30px;
  }
}

.override-tinymce-table {

  @media (max-width: 766px) {
    table-layout: fixed;

    td {
      width: 100% !important;
      white-space: pre;           /* CSS 2.0 */
      white-space: pre-wrap;      /* CSS 2.1 */
      white-space: pre-line;      /* CSS 3.0 */
      white-space: -pre-wrap;     /* Opera 4-6 */
      white-space: -o-pre-wrap;   /* Opera 7 */
      white-space: -moz-pre-wrap; /* Mozilla */
      white-space: -hp-pre-wrap;  /* HP Printers */
      white-space: -webkit-pre-wrap;
      word-wrap: break-word;      /* IE 5+ */
    }
  }

  td {
    width: 30% !important;
  }
}

.page-newsletter .img-responsive {
  margin: 0 auto;

  @media (min-width: 767px) {
    max-width: 750px;
  }
}

.no-padding-left {
  padding-left: 0;
}

.page-promotion-header-image {
  margin-bottom: 30px;

  @media (min-width: $screen-md) {
    img {
      max-height: 400px;
      max-width: 600px;
    }
  }
}

.page-promotion-main-content {
  h4 {
    font-size: 2em;
  }
}

.form-promotion {
  .dropdown-menu {
    position: relative;
    width: 100%;
  }
}

.no-gutter-left {
  padding-left: 0;
}

.no-gutter-right {
  padding-right: 0;
}

.no-gutter-xs {
  @media (max-width: $screen-sm-min) {
    padding-left: 0;
    padding-right: 0;
  }
}